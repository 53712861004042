html, body {height: 100%; width: 100%; font-size: 16px; background-color: #000000;}
body {
    font-family: 'Orbitron', Arial, sans-serif;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
    font-style: normal;
    color: #ffffff;
    scroll-behavior: smooth;
    background-color: #000000;
}

*, *::after, *::before{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul[class],
ol[class] {
  list-style: none;
}

ul,
ol {
  list-style-position: inside;
}
ul li:focus,
ol li:focus {
  outline: solid transparent;
}

/* --- TYPOGRAPHY --- */

.orbitron{
    font-family: 'Orbitron';
}
.press-start-2p{
    font-family: 'Press Start 2P';
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Press Start 2P';
    font-weight: 400;
    text-transform: uppercase;
}
h3, h5,
.h3, .h5 {
    font-family: 'Orbitron';
}

h1,.h1 {
    font-size: 70px;
}

h2,.h2 {
    font-size: 54px;
}

h3,.h3 {
    font-size: 25px;
    font-weight: 900;
}

h4,.h4 {
    font-size: 18px;
}

h5,.h5 {
    font-size: 18px;
    font-weight: 700;
}

@media screen and (min-width: 768px) {
    h1,.h1 {
        font-size: 26.7px;
    }

    h2,.h2 {
        font-size: 18px;
    }

    h3,.h3 {
        font-size: 20px;
    }

    h4,.h4 {
        font-family: 'Orbitron';
        font-size: 14px;
    }

    h5,.h5 {
        font-size: 12px;
    }
}

/* --- LINKS --- */

a {
    color: #9adfff;
    text-decoration: underline;
    transition: color 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
}
a:hover, a:focus {
    color: #24ed80;
    text-decoration: none;
    box-shadow: none;
    outline: solid transparent;

}

/* --- BUTTONS --- */

/* --- FORMS --- */

.form-group {
    margin-bottom: 50px ;
    position: relative;
}

label {
    margin: 0;
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 10px;
}

@media screen and (min-width: 757px){
    label {
        font-size: 22px;
    }
}
@media screen and (min-width: 992px){
    label {
        font-size: 20px;
    }
}


.has-error label{
    color: var(--studio__blue, #1A73E8);
}
    label .required {
        font-size: inherit;
        line-height: 1;
        font-weight: 400;
        color: var(--studio__blue-hover, #0D47A1);
        margin-left: 5px;
    }

    .form-group .help-block{
        position: absolute;
        left: 0;
        top: 100%;
        width: auto;
        max-width: 100%;
        font-size: 12px;
        color: var(--studio__blue, #1A73E8);
        text-align: left;
        margin-top: 10px;
        display: none;
    }
        .form-group.has-error .help-block{
            display: block;
        }

input[type="text"], input[type="password"], input[type="email"], textarea, .form-control {
    padding: 10px 0;
    height: 50px;
    background: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ffffff80;
    box-shadow: none;
    color: var(--studio__white, #fff);
    font-size: 18px;
    font-weight: 500;
    font-family: 'Fira-Sans';
}

input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, textarea:focus, .form-control:focus {
    background-color: transparent;
    outline: solid transparent;
    box-shadow: none;
    border-color: #ffffff80;
    color: var(--studio__white, #fff);
}

.has-error > input[type="text"], .has-error > input[type="password"],
.has-error > input[type="email"], .has-error > textarea, .has-error > .form-control {border-bottom-color: var(--studio__blue, #1A73E8);}

textarea, textarea.form-control {
    padding: 10px 0;
    overflow: hidden;
    resize: none;
    width: 100%;
    height: 50px;
}

.form-control::-webkit-input-placeholder {
    color: #ffffff80;
    font-style: normal;
    font-weight: 400;
}
.form-control::-moz-placeholder {
    color: #ffffff80;
    font-style: normal;
    font-weight: 400;
}
.form-control:-moz-placeholder {
    color: #ffffff80;
    font-style: normal;
    font-weight: 400;
}
.form-control:-ms-input-placeholder {
    color: #ffffff80;
    font-style: normal;
    font-weight: 400;
}