/* media="screen and (min-width: 1200px)" */
@media screen and (min-width: 1200px) {
    header {
        font-size: 18px;
        line-height: 18px;
    }

        header .logo {
            width: 25%;
        }

        header ul {
            width: 50%;
        }

        header .cta-link {
            width: 25%;
            padding: 0 86px 0 0;
            justify-content: end;
        }

    #section-project .content-block .dino-image {
        height: 500px;
    }

    /* --- SECTION ROADMAP --- */

    #section-roadmap .roadmap-milestone {
        width: 1014px;
    }

    /* --- SECTION CONSTRUCTOR --- */

    #section-constructor {
        width: 1014px;
    }

    /* --- SECTION TEAM --- */

    #section-team {
        width: 1014px;
    }

    /* --- FOOTER --- */

        footer .footer-top {
            font-size: 18px;
            line-height: 18px;
        }

            footer .footer-top .logo {
                width: 25%;
            }

            footer .footer-top ul {
                width: 50%;
            }

            footer .footer-top .cta-link {
                width: 25%;

                font-size: 25px;

                padding: 0 0 0 0;
                justify-content: center;
            }
}