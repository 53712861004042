header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0;
    position: fixed;
    z-index: 1;

    top: 0;
    left: 0;

    width: 100%;
    height: 120px;

    background-color: #000000;

    font-family: 'Press Start 2P', Arial, sans-serif;
    font-size: 18px;
    line-height: 18px;
}

    header .logo {
        width: 50%;
        display: flex;
    }

        header .logo img {
            margin: 45px 0 0 39px;
            height: 50px;
        }

    header ul {
        margin: 67px 0 0 0;
        padding: 0;
        width: 66%;
        height: 50px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        display: none;
    }

        header ul li {
            margin: 0;
            padding: 0;
            list-style: none;
        }

            header ul li a {
                color: #ffffff;
                text-decoration: none;
            }
            header ul li a:hover {
                color: #FFE67F;
            }

    header .cta-link {
        margin: 67px 0 0 0;
        padding: 0 86px 0 0;
        width: 17%;
        height: 50px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: end;

        color: #ffffff;
        text-decoration: none;
        display: none;
    }
    header .cta-link:hover {
        color: #24ED80;
    }

    header .menu-link {
        margin: 45px 0 0 0;
        padding: 0 39px 0 0;
        width: 50%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: end;

        color: #ffffff;
        text-decoration: none;
    }
    header .menu-link:hover {
        color: #24ED80;
    }

    header nav {
        background-color: #000000;

        display: none;
        flex-direction: column;
        justify-content: space-between;

        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    header nav.open {
        display: flex;
    }

        header nav .nav-header {
            padding: 45px 39px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

            header nav .nav-header .logo {
            }

                header nav .nav-header .logo img {
                    margin: 0;
                }

        header nav ul.main-menu {
            margin: 0;
            padding: 0;

            display: block;
            width: 100%;
            height: auto;
        }

            header nav ul.main-menu li {
                margin: 0 0 50px 0;
                padding: 0;
                list-style: none;

                text-align: center;
                width: 100%;
            }

                header nav ul.main-menu li a {
                    color: #ffffff;
                }

        header nav .cta-link {
            margin: 0;
            padding: 0;

            display: block;
            text-align: center;
            width: 100%;

            color: #24ED80;
        }

        header nav ul.socialLinks {
            margin: 0 0 36px 0;
            padding: 0;

            display: flex;
            flex-direction: row;
            justify-content: center;

            width: 100%;
            height: auto;
        }

            header nav ul.socialLinks li {
                margin: 0 18px;
                padding: 0;
                list-style: none;

                text-align: center;
                width: auto;
            }

                header nav ul.socialLinks li a {
                    color: #ffffff;
                }

/* --- SECTION PROJECT --- */

#section-project {
    margin: 120px 0 0 0;
}

    #section-project #side-links {
        display: none;
    }

        #section-project #side-links ul {
            margin: 240px 0 0 0;
            padding: 0;
            width: 320px;
            height: 150px;
            display: flex;
            transform: rotate(-90deg);
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
        }

            #section-project #side-links li {
                margin: 0;
                padding: 0 120px 0 0;
                list-style: none;

                font-family: 'Press Start 2P', Arial, sans-serif;
                font-size: 18px;
                line-height: 1;
            }

                #section-project #side-links li a {
                    text-decoration: none;
                    position: relative;
                }

                    #section-project #side-links li a:before {
                        content: "";
                        display: block;
                        width: 16px;
                        height: 16px;
                        left: -26px;
                        position: absolute;
                    }

                #section-project #side-links li:first-child a {
                    color: #FFE67F;
                }
                    #section-project #side-links li:first-child a:before {
                        background-color: #FFE67F;
                    }
                #section-project #side-links li:last-child a {
                    color: #9adfff;
                }
                    #section-project #side-links li:last-child a:before {
                        background-color: #9adfff;
                    }

                #section-project #side-links li a:hover {
                    color: #24ED80;
                }
                    #section-project #side-links li a:hover:before {
                        background-color: #24ED80;
                    }

    #section-project .content-block {
        margin: 0 0 0 0;
        width: 100%;

        overflow: hidden;
    }

        #section-project .content-block .price-container {
            padding: 27px;
            width: 100%;
            height: 276px;
            background-color: #fff;
            color: #000;
            font-family: 'Press Start 2P', Arial, sans-serif;
            font-size: 64px;
            line-height: 1;
            text-transform: uppercase;
            position: relative;
        }

            #section-project .content-block .dino-image {
                bottom: -25%;
                right: -12%;
                height: 200px;
                position: absolute;
            }

        #section-project .content-block h1 {
            margin: 27px 0 27px 27px;
            max-width: 230px;
            line-height: 25px;
        }

        #section-project .content-block p {
            margin: 0;
            padding: 0 27px;
            max-width: 100%;

            font-family: 'Orbitron', Arial, sans-serif;
            font-weight: 700;

            font-size: 14px;
            line-height: 18px;
        }

        #section-project .content-block p.lead {
            margin: 56px 0 0 0;
            width: 100%;
            max-width: 100%;

            font-family: 'Press Start 2P', Arial, sans-serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
            text-transform: uppercase;
        }

    #section-project .content-slider {
        margin: 46px 0 0 0;
        width: 100%;
    }

        #section-project .content-slider .slider-item {
            padding: 10px;
        }

            #section-project .content-slider .slider-item img {
                width: 100%;
            }

        #section-project .content-slider p {
            margin: 20px 50px 0px 50px;
            font-family: 'Orbitron', Arial, sans-serif;
            font-size: 18px;
            line-height: 23px;
            text-align: center;
        }

/* --- SECTION ROADMAP --- */

#section-roadmap {
    margin: 45px 0 0 0;
    padding: 75px 0 0 10px;
    background: url(../../img/roadmap-bg.png) repeat-x 50% 0;
    background-size: auto 25px;
    width: 100%;
    position: relative;
}

    #section-roadmap .roadmap-milestone {
        margin: 0 auto;
        padding: 56px 26px 30px 26px;
        background: url(../../img/pixel-border.png) repeat-x 0 0;
        background-size: auto 16px;
        width: auto;

        position: relative;
    }
    #section-roadmap .roadmap-milestone:last-child {
        padding-bottom: 56px;
    }
        #section-roadmap .roadmap-milestone:last-child:before {
            content: "";
            background: url(../../img/pixel-border.png) repeat-x 0 0;
            bottom: 10px;
            left: 0;
            width: 100%;
            height: 16px;
            position: absolute;
        }

        #section-roadmap .roadmap-milestone:first-child:before {
            content: "";
            background: url(../../img/roadmap-logo.png) #000 no-repeat 0 0;
            background-size: contain;
            height: 50px;
            width: 78px;
            display: block;
            top: -17px;
            left: 0;
            position: absolute;
        }

        #section-roadmap .roadmap-milestone:after {
            content: "";
            background: url(../../img/pixel-border-90.png) repeat-y 100% 0;
            background-size: auto 26px;

            width: 16px;
            height: 100%;
            display: block;
            top: 0;
            right: 10px;
            position: absolute;
        }
        #section-roadmap .roadmap-milestone.reversed:after {
            left: 0;
            right: auto;
        }

        #section-roadmap .roadmap-milestone .milestone-description-container {
            display: flex;
            flex-direction: row;
            position: relative;
        }

        #section-roadmap .roadmap-milestone .milestone-value {
            padding: 0 30px 0 0;
            display: flex;

            font-family: 'Press Start 2P', Arial, sans-serif;
            font-size: 27px;
            line-height: 1;
            align-items: center;
        }
        #section-roadmap .roadmap-milestone.reversed .milestone-value {
            padding: 0 0 0 30px;
            order: 2;
        }

        #section-roadmap .roadmap-milestone .milestone-description p:last-child {
            margin-bottom: 0;
        }

        #section-roadmap .roadmap-milestone .milestone-description p:nth-child(1) {
            padding-right: 10px;
            margin-bottom: 0;
        }
        #section-roadmap .roadmap-milestone .milestone-description p:nth-child(2) {
            display: none;
        }

    #section-roadmap .roadmap-milestone .milestone-description-mobile {
        width: 100%;
    }

        #section-roadmap .roadmap-milestone .milestone-description-mobile p {
            margin: 1rem 26px 0 0;
        }
        #section-roadmap .roadmap-milestone.reversed .milestone-description-mobile p {
            margin: 1rem 0 0 0;
        }

/* --- SECTION CONSTRUCTOR --- */

#section-constructor {
    margin: 95px auto 0 auto;
    text-align: center;
    width: 90%;
}

    #section-constructor h2 {
        font-family: 'Press Start 2P', Arial, sans-serif;
        font-size: 27px;
        line-height: 1;
    }
        #section-constructor h2 span:nth-child(1) {color: #C4C5FD;}
        #section-constructor h2 span:nth-child(2) {color: #B1FFC6;}
        #section-constructor h2 span:nth-child(3) {color: #9ADFFF;}
        #section-constructor h2 span:nth-child(4) {color: #FFE67F;}
        #section-constructor h2 span:nth-child(5) {color: #EAEAEA;}
        #section-constructor h2 span:nth-child(6) {color: #FEC2DE;}
        #section-constructor h2 span:nth-child(7) {color: #CBE5F6;}
        #section-constructor h2 span:nth-child(8) {color: #C59797;}
        #section-constructor h2 span:nth-child(9) {color: #B1FFC6;}
        #section-constructor h2 span:nth-child(10) {color: #C4C5FD;}
        #section-constructor h2 span:nth-child(11) {color: #FFE67F;}

    #section-constructor p.subheader {
        margin: 20px 0 35px 0;
        font-family: 'Press Start 2P', Arial, sans-serif;
        font-size: 18px;
        line-height: 1;
    }

    #section-constructor ul.content-block {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: start;
        flex-direction: row;
    }

        #section-constructor ul.content-block li {
            margin: 0;
            padding: 20px 0;
            list-style: none;
            text-align: center;
            font-size: 20px;
            line-height: 1;
            width: 50%;
        }

            #section-constructor ul.content-block li:nth-child(1) {color: #CBE5F6;}
            #section-constructor ul.content-block li:nth-child(2) {color: #C59797;}
            #section-constructor ul.content-block li:nth-child(3) {color: #9ADFFF;}
            #section-constructor ul.content-block li:nth-child(4) {color: #EAEAEA;}
            #section-constructor ul.content-block li:nth-child(5) {color: #B1FFC6;}
            #section-constructor ul.content-block li:nth-child(6) {color: #C4C5FD;}
            #section-constructor ul.content-block li:nth-child(7) {color: #FFE67F;}
            #section-constructor ul.content-block li:nth-child(8) {color: #FEC2DE;}

/* --- SECTION TEAM --- */

#section-team {
    margin: 95px auto 0 auto;
    text-align: center;
    width: 90%;
}

    #section-team .content-block {
        margin: 0;
        padding: 60px 0 0 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
    }

        #section-team .content-block .team-member {
            padding: 0 0 50px 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            color: #ffffff;
            text-decoration: none;

            font-weight: 900;
            font-size: 25px;
            line-height: 1;
        }

            #section-team .content-block .team-member img {
                margin: 0 0 40px 0;
                height: 110px;
            }

/* --- FOOTER --- */

footer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    width: 100%;

    background-color: #fff;
}

    footer .footer-top {
        display: none;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0;
        position: relative;
        z-index: 1;

        width: 100%;
        height: 120px;

        background-color: #fff;

        font-family: 'Orbitron', Arial, sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        color: #000000;
    }

        footer .footer-top .logo {
            width: 50%;
            display: flex;
        }

            footer .footer-top .logo img {
                margin: 45px 0 0 39px;
                height: 50px;
            }

        footer .footer-top ul {
            margin: 67px 0 0 0;
            padding: 0;
            width: 66%;
            height: 50px;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            display: none;
        }

            footer .footer-top ul li {
                margin: 0;
                padding: 0;
                list-style: none;
            }

                footer .footer-top ul li a {
                    color: #000000;
                    text-decoration: none;
                }
                footer .footer-top ul li a:hover {
                    color: #24ED80;
                }

        footer .footer-top .cta-link {
            margin: 67px 0 0 0;
            padding: 0 86px 0 0;
            width: 17%;
            height: 50px;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: end;

            font-size: 25px;

            color: #000000;
            text-decoration: none;
            display: none;
        }
        footer .footer-top .cta-link:hover {
            color: #24ED80;
        }

        footer .footer-top-mobile {
            display: block;
        }

            footer .footer-top-mobile .cta-link {
                margin: 45px 0 45px 0;

                font-size: 25px;
                line-height: 32px;
                font-weight: 900;

                text-align: center;

                color: #000000;
                text-decoration: none;
                display: block;
            }
            footer .footer-top-mobile .cta-link:hover {
                color: #24ED80;
            }

        footer .footer-top-mobile ul {
            margin: 0;
            padding: 0;
            text-align: center;
        }

            footer .footer-top-mobile ul li {
                margin: 0;
                padding: 0;
                list-style: none;

                font-weight: 700;
                font-size: 14px;
                line-height: 30px;
            }

                footer .footer-top-mobile ul li a {
                    color: #000000;
                    text-decoration: none;
                }
                footer .footer-top-mobile ul li a:hover {
                    color: #24ED80;
                }

        footer .follow-us {
            margin: 35px auto 0 auto;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            display: flex;

            width: 250px;

            font-family: 'Orbitron', Arial, sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 1;
            color: #000;
        }

            footer .follow-us ul {
                margin: 0;
                padding: 0;
            }

                footer .follow-us ul li {
                    margin: 0;
                    padding: 0 12px;
                    list-style: none;
                    display: inline-block;
                }

                    footer .follow-us ul li img {
                        width: 24px;
                        height: 24px;
                    }

        footer .copyrights {
            margin: 50px 0 30px 0;
            text-align: center;

            font-family: 'Orbitron', Arial, sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            color: #000;
        }

            footer .copyrights a.logo img {
                margin: 0px auto 25px auto;
                height: 50px;

                display: block;
            }