/* media="screen and (min-width: 1920px)" */
@media screen and (min-width: 1920px) {
    header .logo {
        width: 17%;
    }

    header ul {
        width: 66%;
    }

    header .cta-link {
        width: 17%;
    }

    /* --- FOOTER --- */

        footer .footer-top .logo {
            width: 17%;
        }

        footer .footer-top ul {
            width: 66%;
        }

        footer .footer-top .cta-link {
            width: 17%;
        }
}