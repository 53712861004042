/* media="screen and (min-width: 1440px)" */
@media screen and (min-width: 1440px) {
    header .logo {
        width: 25%;
    }

    header ul {
        width: 50%;
    }

    header .cta-link {
        width: 25%;
    }

    #section-project .content-block .dino-image {
        height: 600px;
    }

    /* --- SECTIO`N CONSTRUCTOR --- */

    #section-constructor {
        width: 1274px;
    }

    /* --- SECTION TEAM --- */

    #section-team {
        width: 1274px;
    }

    /* --- FOOTER --- */

        footer .footer-top .logo {
            width: 25%;
        }

        footer .footer-top ul {
            width: 50%;
        }

        footer .footer-top .cta-link {
            width: 25%;
        }
}