/* media="screen and (min-width: 992px)" */
@media screen and (min-width: 992px) {
    header {
        font-size: 16px;
        line-height: 16px;

        height: 200px;
    }

        header .logo {
            width: 15%;
        }

            header .logo img {
                margin: 67px 0 0 86px;
            }

        header ul {
            width: 60%;
            display: flex;
        }

        header .cta-link {
            display: flex;
            width: 25%;
            padding: 0 0 0 0;
            justify-content: start;
        }

        header .menu-link {
            display: none;
        }

        header nav {
            display: none;
        }

    /* --- SECTION PROJECT --- */

    #section-project {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        padding: 0;
        position: relative;

        margin: 200px 0 0 0;
    }

        #section-project #side-links {
            width: 17%;
            display: flex;
        }

        #section-project .content-block {
            margin: 0 17% 0 0;
            width: 66%;

            overflow: visible;
        }

            #section-project .content-block .price-container {
                padding: 113px;
                width: 100%;
                height: 600px;
                background-color: #fff;
                color: #000;
                font-family: 'Press Start 2P', Arial, sans-serif;
                font-size: 150px;
                line-height: 1;
                text-transform: uppercase;
                position: relative;
            }

            #section-project .content-block .dino-image {
                height: 400px;
            }

            #section-project .content-block h1 {
                margin: 90px 0 18px 0;
                max-width: 700px;
                line-height: 31px;
            }

            #section-project .content-block p {
                margin: 0;
                padding: 0;
                max-width: 700px;

                font-family: 'Orbitron', Arial, sans-serif;
                font-weight: 700;

                font-size: 18px;
                line-height: 23px;
            }

            #section-project .content-block p.lead {
                margin: 130px 0 0 0;
                font-size: 54px;
                line-height: 60px;
            }

        #section-project .content-slider {
            margin: 120px 0 0 0;
        }

    /* --- SECTION ROADMAP --- */

    #section-roadmap {
        margin: 185px 0 0 0;
        padding: 140px 0 0 0;
        background: url(../../img/roadmap-bg.png) repeat-x 50% 0;
        background-size: auto 50px;
        width: 100%;
    }

        #section-roadmap .roadmap-milestone {
            padding: 100px;
            width: 754px;
        }

            #section-roadmap .roadmap-milestone .milestone-value {
                padding: 0 100px 0 0;
                display: flex;

                font-family: 'Press Start 2P', Arial, sans-serif;
                font-size: 70px;
                line-height: 1;
                align-items: center;
            }

            #section-roadmap .roadmap-milestone .milestone-description p:nth-child(1) {
                margin-bottom: 1rem;
            }
            #section-roadmap .roadmap-milestone .milestone-description p:nth-child(2) {
                display: block;
            }

        #section-roadmap .roadmap-milestone .milestone-description-mobile {
            display: none;
        }

    /* --- SECTION CONSTRUCTOR --- */

    #section-constructor {
        margin: 185px auto 0 auto;
        width: 754px;
    }

        #section-constructor h2 {
            font-size: 54px;
        }

        #section-constructor p.subheader {
            margin: 40px 0 70px 0;
            font-size: 30px;
        }

        #section-constructor ul.content-block li {
            width: 25%;
        }

    /* --- SECTION TEAM --- */

    #section-team {
        margin: 185px auto 0 auto;
        width: 754px;
    }

        #section-team h2 {
            font-size: 54px;
        }

        #section-team .content-block .team-member {
            width: 50%;
        }

    /* --- FOOTER --- */

        footer .footer-top {
            font-size: 16px;
            line-height: 16px;

            height: 120px;
            display: flex;
        }

            footer .footer-top .logo {
                width: 15%;
            }

                footer .footer-top .logo img {
                    margin: 67px 0 0 86px;
                }

            footer .footer-top ul {
                width: 60%;
                display: flex;
            }

            footer .footer-top .cta-link {
                display: flex;

                font-size: 21px;

                width: 25%;
                padding: 0 0 0 0;
                justify-content: start;
            }

        footer .footer-top-mobile {
            display: none;
        }

            footer .copyrights a.logo img {
                display: none;
            }
}